import React, {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  ClipboardEvent,
  useMemo,
  useCallback,
  Ref,
  HTMLInputTypeAttribute,
  forwardRef,
  ReactNode,
  RefAttributes,
  useImperativeHandle,
} from 'react';
import { ariaAttributeProps, dataAttributeProps } from '../../../utils/ComponentUtils';
import Error from '../error/Error';
import XIcon from '../icon/XIcon';
import withSlot, { SlotDefinitions } from '../../../wrappers/withSlot';
import useSlot from '../../../hooks/useSlots';
import InfoIcon from '../icon/InfoIcon';
import Tooltip from '../Tooltip';
import { DynamicDataInterfaceHandle } from './InputWithDynamicData';

export interface InputProps {
  name?: string;
  value?: string;
  id?: string;
  type?: HTMLInputTypeAttribute;
  className?: string;
  wrapperClassName?: string;
  style?: InputStyle;
  placeholder?: string;
  label?: string;
  labelVisible?: boolean;
  textColor?: string;
  borderColor?: string;
  disabled?: boolean;
  readonly?: boolean;
  autocomplete?: string;
  autoFocus?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: KeyboardEvent) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void; // react version of onKeyPress
  onClear?: () => void;
  error?: string;
  errorState?: boolean;
  min?: number;
  max?: number;
  maxLength?: number;
  innerRef?: Ref<HTMLInputElement | null>;
  onPaste?: (event: ClipboardEvent) => void;
  helpText?: string;
  required?: boolean;
}

export enum InputStyle {
  COMPOUND,
  NORMAL,
  MINIMAL,
  PLAIN,
}

const fallbackAutoComplete = 'auto';

const InputComponent = forwardRef<DynamicDataInterfaceHandle, InputProps & { children?: ReactNode; _slots: 'leading' | 'trailing' }>(
  function Input(props, ref) {
    const {
      id,
      name,
      value,
      type = 'text',
      borderColor = 'border-primary-1',
      textColor = 'text-primary-1',
      onChange,
      onFocus,
      onBlur,
      onClear,
      placeholder = '',
      label,
      className,
      style,
      disabled,
      readonly,
      autocomplete,
      onKeyPress,
      autoFocus,
      error,
      errorState,
      min,
      max,
      innerRef,
      maxLength,
      onPaste,
      wrapperClassName,
      helpText,
      required,
      onKeyDown,
    } = props;

    const [hasFocus, setHasFocus] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const hasLabel = label && label.length > 0;

    const inputHasLabelClasses = useMemo(() => (hasLabel ? 'mt-9' : ''), [hasLabel]);
    const inputStyleClasses = useMemo(() => {
      let result = `w-full ${disabled || readonly ? 'text-color-3' : textColor} placeholder-gray-2 `;

      switch (style) {
        case InputStyle.COMPOUND:
          result += 'px-2 py-2 my-0 outline-none rounded-lg bg-white';
          break;

        case InputStyle.MINIMAL:
          result += 'px-2 py-1 outline-none rounded-lg text-dpm-14 bg-white';
          break;

        case InputStyle.PLAIN:
          result += 'px-1 py-1 outline-none rounded-lg bg-transparent';
          break;

        case InputStyle.NORMAL:
        default:
          result += 'px-2 py-2 outline-none rounded-lg bg-white';
          break;
      }

      return result;
    }, [disabled, readonly, style, textColor]);

    const wrapperStyleClasses = useMemo(() => {
      return `border-2 ${errorState ? 'border-red-500' : ''} ${
        hasFocus && !readonly && !errorState && style !== InputStyle.PLAIN ? 'border-accent-1' : disabled ? 'border-gray-4 text-color-3' : borderColor
      } rounded-lg`;
    }, [errorState, hasFocus, readonly, style, borderColor, disabled]);

    const onInputFocus = (event: FocusEvent<HTMLInputElement>) => {
      setHasFocus(true);
      onFocus && onFocus(event);
    };

    const onInputBlur = (event: FocusEvent<HTMLInputElement>) => {
      setHasFocus(false);
      onBlur && onBlur(event);
    };

    useEffect(() => {
      if (!onKeyPress) {
        return;
      }

      const handler = (event: KeyboardEvent) => {
        onKeyPress(event);
      };
      const ref = containerRef.current;

      ref?.addEventListener('keydown', handler);

      return () => {
        ref?.removeEventListener('keydown', handler);
      };
    }, [onKeyPress]);

    const slotClick = useCallback((e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      inputRef.current?.focus();
    }, []);

    const assignRef = useCallback(
      (ref: HTMLInputElement | null) => {
        if (!ref) {
          return;
        }
        if (typeof innerRef === 'function') {
          (innerRef as (instance: HTMLInputElement | null) => void)(ref);
        } else if (innerRef) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (innerRef.current as any) = ref;
        }
        inputRef.current = ref;
      },
      [innerRef],
    );

    const leadingSlot = useSlot(props, 'leading');
    const trailingSlot = useSlot(props, 'trailing');

    const [savedPosition, setSavedPosition] = useState<number | null>(null);
    useImperativeHandle(
      ref,
      () => ({
        savePosition: () => {
          setSavedPosition(inputRef.current?.selectionStart ?? 0);
        },
        insertPlaceholder: (placeholder: string) => {
          if (savedPosition === null) return;

          let result = value || '';
          result = `${result.slice(0, savedPosition)}${placeholder}${result.slice(savedPosition + 1)}`; // +1 to remove the `/` character
          onChange?.({ target: { value: result } } as unknown as ChangeEvent<HTMLInputElement>);
        },
      }),
      [onChange, savedPosition, value],
    );

    return (
      <div
        className={`relative w-full ${inputHasLabelClasses} ${disabled ? 'pointer-events-none opacity-50' : ''}`}
        ref={containerRef}
        data-cy="input-wrapper"
        data-has-label={!!label}
      >
        {label && label.length > 0 && (
          <div className={`absolute left-0 top-0 ${helpText ? '-mt-7' : '-mt-5'}`}>
            <div className="flex items-center gap-2">
              <label htmlFor={id} className={`text-color-3 text-dpm-12 transition-opacity duration-150 ease-in-out`}>
                {required && <span className="text-semantic-3 pr-1 font-semibold">*</span>}
                {label}
              </label>
              {!!helpText && (
                <Tooltip text={helpText}>
                  {(tooltip) => (
                    <div {...tooltip}>
                      <InfoIcon className="h-4 w-4" />
                    </div>
                  )}
                </Tooltip>
              )}
            </div>
          </div>
        )}
        <div className={`flex cursor-text items-center ${wrapperStyleClasses} ${wrapperClassName} bg-white`}>
          {/* Only used for mis-clicks on the input */}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div className="flex flex-shrink-0 items-center justify-center overflow-hidden pl-2 empty:hidden" onMouseDown={slotClick}>
            {leadingSlot()}
          </div>
          <input
            {...dataAttributeProps(props)}
            {...ariaAttributeProps(props)}
            id={id}
            ref={assignRef}
            name={name}
            className={`${inputStyleClasses} ${className}`}
            value={value}
            placeholder={placeholder}
            type={type}
            onChange={onChange}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            disabled={disabled}
            readOnly={readonly}
            autoComplete={autocomplete || name || fallbackAutoComplete}
            data-testid="input-field"
            autoFocus={autoFocus}
            min={min}
            max={max}
            maxLength={maxLength}
            onPaste={onPaste}
            aria-label={name || 'input-field'}
            onKeyDown={onKeyDown}
          />
          {/* Only used for mis-clicks on the input */}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div className="flex flex-shrink-0 items-center justify-center overflow-hidden empty:hidden" onMouseDown={slotClick}>
            {maxLength && (
              <span className="text-gray-2 text-dpm-14 px-2 pt-1">
                {value?.length}/{maxLength}
              </span>
            )}
            {trailingSlot() ||
              (!!onClear && (
                <XIcon className="text-color-3 disabled:text-color-3 placeholder-gray-3 mr-2 h-4 w-4" onClick={() => !disabled && onClear()} />
              ))}
          </div>
        </div>
        <Error>{error}</Error>
      </div>
    );
  },
);

export const Input = withSlot<InputProps & RefAttributes<DynamicDataInterfaceHandle>, SlotDefinitions<['leading', 'trailing']>>(InputComponent);
